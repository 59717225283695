import { template as template_cf4db62807a344b298d1049e4c662d0e } from "@ember/template-compiler";
const SidebarSectionMessage = template_cf4db62807a344b298d1049e4c662d0e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
