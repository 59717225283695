import { template as template_c0a0c8ca725a4ff68bb4001ef5da46a1 } from "@ember/template-compiler";
const FKText = template_c0a0c8ca725a4ff68bb4001ef5da46a1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
