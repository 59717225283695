import { template as template_284f60d84f2645238b6e3e84ab416b97 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_284f60d84f2645238b6e3e84ab416b97(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
